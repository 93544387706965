import React from "react"

export default function Cover({ children, image }) {
  return (
    <section className="h-[40rem] md:h-screen overflow-hidden grid pt-6 lg:pt-0">
      <div className="col-start-1 row-start-1 relative z-0">{image}</div>
      <div className="col-start-1 row-start-1 relative z-10 bg-gradient-to-t to-black/50 from-black/0">
        <div className="w-11/12 xl:w-full mx-auto relative -top-12 lg:-top-32">
          {children}
        </div>
      </div>
    </section>
  )
}
