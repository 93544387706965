import React from "react"
import Hero from "../components/new-landing-page/Hero"

import Seo from "../components/seo"
import HighLight from "../components/new-landing-page/Highlight"
import Experience from "../components/new-landing-page/Experience"
import Exclusive from "../components/new-landing-page/Exclusive"
import FooterArea from "../components/new-landing-page/FooterArea"
import Offer from "../components/new-landing-page/Offer"
export default function Index() {
  return (
    <>
      <Seo title="Home" />
      <Hero />
      <HighLight />
      <Experience />

      <Exclusive />
      <Offer />
      <FooterArea />
    </>
  )
}
