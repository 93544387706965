import React from "react"
import Cover from "./Section/Cover"
import { StaticImage } from "gatsby-plugin-image"
export default function Exclusive() {
  const list = [
    {
      image: (
        <StaticImage
          src="../../images/Icon.svg"
          alt="icon"
          className="w-10 mx-auto"
        />
      ),
      title: "Curated Properties",
      description: "Hand-picked villas for your stay",
    },
    {
      image: (
        <StaticImage
          src="../../images/Vector (1).svg"
          alt="icon"
          className="w-10 mx-auto"
        />
      ),
      title: "Explore",
      description:
        "Bringing Together the Best of Everything Ibiza has to Offer        ",
    },
    {
      image: (
        <StaticImage
          src="../../images/Vector.png"
          alt="icon"
          className="w-12 mx-auto"
        />
      ),
      title: "Exclusive Experiences",
      description:
        "Go VIP and get access to magical spots, the best club tables and much more        ",
    },
  ]
  return (
    <>
      <Cover
        image={
          <StaticImage
            alt="experience-2"
            src="../../images/index3/cover-exclusive.jpeg"
            className="h-full w-full object-cover"
          />
        }
      >
        <div className="flex h-screen items-center w-full text-white">
          <div className="w-full lg:w-1/2 mx-auto text-center">
            <h3 className="font-serif3 text-2xl lg:text-3xl lg:leading-[56px] font-bold">
              Exclusive Insider Access
            </h3>
            <p className="text-[16px] py-8 max-w-lg mx-auto">
              Ibiza has some awesome hidden gems that most tourists don’t know
              about or usually don’t know how to get access to. From beaches,
              parties and adventures to wellness, healing ceremonies and more.
            </p>
            <p className="uppercase font-bold ">
              We give you insider access, <br />
              helping you discover hidden gems.
            </p>
          </div>
        </div>
      </Cover>
      <section className="max-w-screen-lg w-11/12 xl:w-full mx-auto relative z-30 -mt-24 lg:-mt-44 py-12">
        <div className="grid gap-8 md:gap-0 md:flex md:space-x-8">
          {list.map((s, key) => {
            return (
              <div
                key={key}
                className="h-72 text-white bg-[#A5A58F] w-full shadow-lg shadow-[#A5A58F]/40 flex justify-center items-center"
              >
                <div className="space-y-3 flex flex-col justify-center">
                  {s.image}
                  <div className="flex flex-col gap-2">
                    <h3 className=" font-serif3 text-center">{s.title}</h3>
                    <p className="text-xs max-w-[14.3rem] w-11/12 lg:w-full mx-auto text-center font-light">
                      {s.description}
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </section>
    </>
  )
}
