import React from "react"

export default function Divide({ className, children, image }) {
  return (
    <section className={`relative ${className}`}>
      <div className="max-w-screen-lg w-full sm:w-11/12 xl:w-full grid mx-auto lg:flex">
        <div className="lg:w-1/2 col-start-1 row-start-2 w-11/12 sm:w-full mx-auto sm:mx-0">
          {children}
        </div>
        <div className="lg:w-1/2 relative z-0 lg:absolute lg:right-0 lg:h-full col-start-1 row-start-1">
          {image}
        </div>
      </div>
    </section>
  )
}
