import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export default function Offer() {
  const list = [
    {
      title: "Book It",
      description:
        "Get the best selection of quality places to stay, verified by us",
    },
    {
      title: "Personalize Your Vacation",
      description:
        "Use our Vacation Planner to create your own itinerary        ",
    },
    {
      title: "Let us Take Care of it",
      description:
        "Let us handle everything for you, enjoy your perfect vacation        ",
    },
  ]
  return (
    <section className="grid h-auto lg:h-[80vh]">
      <div className=" col-start-1 row-start-1 relative z-0">
        <StaticImage
          alt="offer"
          className="h-full lg:h-[80vh] w-full object-cover"
          src="../../images/index3/pexels-rachel-claire-8112477.jpg"
        />
      </div>
      <div className="flex items-center h-full col-start-1 row-start-1 relative z-10 bg-gradient-to-t to-black/40 from-black/60">
        <div className="w-11/12 text-center xl:w-full max-w-screen-lg mx-auto text-white py-12 lg:py-0">
          <h4 className="font-serif3 mx-auto max-w-2xl text-2xl lg:text-3xl lg:leading-[40px] font-bold">
            Bringing Together the Best of Everything Ibiza has to Offer
          </h4>
          <p className="text-[16px] py-8 max-w-lg mx-auto w-full">
            Our pledge is to give the highest quality standards of stays,
            experiences, and services to make your Ibiza stay magical
          </p>
          <div>
            <ul className="grid md:grid-cols-3 gap-8 pt-20">
              {list.map((li, key) => {
                return (
                  <li key={key} className="flex space-x-4">
                    <span className="text-[80px] font-bold text-[#EADDD3] leading-[1] font-serif3">
                      {key + 1}
                    </span>
                    <div className="grid text-left">
                      <strong>{li.title}</strong>
                      <p>{li.description}</p>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
