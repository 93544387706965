import React from "react"
import Cover from "./Section/Cover"
import { StaticImage } from "gatsby-plugin-image"
export default function Experience() {
  const image = [
    <StaticImage
      alt="pip"
      className="h-full w-full object-cover"
      src="../../images/index3/junglee-2.png"
    />,
    <StaticImage
      alt="pip"
      className="h-full w-full object-cover"
      src="../../images/index3/dj.jpeg"
    />,
    <StaticImage
      alt="pip"
      className="h-full w-full object-cover"
      src="../../images/index3/keerla.jpeg"
    />,
  ]

  const properties = [
    {
      name: "Villa Opel",
      area: "Cala Tarida",
      bedrooms: 6,
      bathrooms: 6,
      image: (
        <StaticImage
          src="../../images/index3/villa-opel.png"
          alt="property"
          className="h-56 md:h-64 object-cover w-full"
        />
      ),
    },
    {
      name: "Villa Arabella",
      area: "Vista Alegre",
      bedrooms: 4,
      bathrooms: 4,
      image: (
        <StaticImage
          src="../../images/index3/villa-arabella.png"
          alt="property"
          className="h-56 md:h-64 object-cover w-full"
        />
      ),
    },
    {
      name: "Skyfall",
      area: "Cap Marinet",
      bedrooms: 6,
      bathrooms: 5,
      image: (
        <StaticImage
          src="../../images/index3/skyfall.png"
          alt="property"
          className="h-56 md:h-64 object-cover w-full"
        />
      ),
    },
  ]

  return (
    <>
      <Cover
        image={
          <StaticImage
            alt="experience"
            src="../../images/index3/experience.jpeg"
            className="h-full lg:h-screen w-full object-cover"
          />
        }
      >
        <div className="flex h-screen items-center w-full text-white">
          <div className="w-full lg:w-1/2 ml-auto">
            <h3 className="font-serif3 text-2xl text-center md:text-left lg:text-3xl lg:leading-[56px] font-bold">
              Experience the Ibiza Lifestyle
            </h3>
            <p className="text-[16px] py-8 text-center md:text-left md:max-w-lg w-full">
              Ibiza with its turquoise beaches sparkle under the sun and famous
              DJs playing under the stars, it’s one of the magical locations in
              the world. People around the world love visiting this place
              because of its eco-chic, and rustic vibes that allow visitors to
              enjoy and relax in the arms of nature.
            </p>
          </div>
        </div>
      </Cover>
      <section className="max-w-screen-lg w-11/12 xl:w-full mx-auto relative -mt-24 z-30 lg:-mt-44 py-12">
        <div className="grid gap-4 md:gap-8 grid-cols-3 md:grid-cols-4">
          <div className="h-72 bg-[#A5A58F] w-full p-5 col-span-3 md:col-span-1">
            <div className="text-white space-y-3">
              <h3 className="font-sans w-3/4 mx-auto md:w-full md:mx-0 text-center md:text-left leading-tight uppercase font-bold text-lg md:text-sm lg:text-lg">
                IBIZA TOWN HAS SUCCESSFULLY PRESERVED ITS UNIQUE HERITAGE AND
                SPANISH CULTURE.
              </h3>
              <p className="font-light w-3/4 mx-auto md:w-full md:mx-0 text-center md:text-left uppercase text-xs">
                We help you plan unique experiences you and your friends and
                family will never forget.
              </p>
            </div>
          </div>
          {[1, 2, 3].map((s, key) => {
            return (
              <div key={key} className="h-56 md:h-72 bg-[#A5A58F] w-full">
                {image[key % image.length]}
              </div>
            )
          })}
        </div>
        <div className="grid md:grid-cols-2 gap-8 pt-8">
          <div className="grid h-[28rem] md:h-80">
            <div className="col-start-1 row-start-1 relative z-0">
              <StaticImage
                src="../../images/index3/premium-property-1.png"
                className="h-[28rem] md:h-80 w-full object-cover"
                alt="hotal"
              />
            </div>
            <div className="col-start-1 row-start-1 space-y-3 relative z-10 bg-gradient-to-t to-black/60 from-black/40 px-6 md:px-12 pt-12">
              <h3 className="text-white font-serif3 font-bold text-2xl md:text-xl text-center md:text-left">
                Premium Vacation Rentals
              </h3>
              <p className="text-white text-center md:text-left font-light text-sm lg:max-w-xs">
                We provide beautiful luxury vacation villa rentals that
                complement the stunning, and historical richness of the
                landscape Ibiza has to offer.
              </p>
              <div className="px-6 md:px-12 pb-12 md:pb-2 lg:pb-7 absolute bottom-0 left-0 w-full">
                <div className="grid text-center md:text-left grid-cols-3 gap-2 md:max-w-sm">
                  <div className="flex flex-col  gap-4 text-white">
                    <strong className=" font-serif3 font-bold text-xl">
                      300+
                    </strong>
                    <span className="uppercase font-bold text-xs">
                      Villas to Choose from
                    </span>
                  </div>
                  <div className="flex flex-col gap-4 text-white">
                    <strong className=" font-serif3 font-bold text-xl">
                      100+
                    </strong>
                    <span className="uppercase font-bold text-xs">
                      Activities to choose
                    </span>
                  </div>
                  <div className="flex flex-col gap-4 text-white">
                    <strong className=" font-serif3 font-bold text-xl">
                      200+
                    </strong>
                    <span className="uppercase font-bold text-xs">
                      Miles to explore
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {properties.map((s, key) => {
            return (
              <div key={key} className="h-80 bg-[#A5A58F] w-full">
                <div className="grid h-[70%] md:h-3/4 overflow-hidden">
                  <div className="col-start-1 row-start-1 relative z-0">
                    {s.image}
                  </div>
                  <div className="col-start-1 row-start-1 relative z-10 bg-gradient-to-t to-black/50 from-black/0 p-8 flex items-end">
                    <div className="text-white grid gap-2">
                      <span className="text-xs">★ ★ ★ ★ ★</span>
                      <h3 className="font-bold text-xl font-serif3">
                        {s.name}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="h-[30%] md:h-1/4 bg-[#F0EFEB] w-full px-8 py-4">
                  <ul className="grid justify-items-center grid-cols-2 md:grid-cols-4 gap-2.5 md:gap-2 text-xs font-bold text-[#7E7E66]">
                    {/* {list.map((s, key) => {
                      return ( */}
                    <li className="flex gap-2">
                      <span>
                        <svg
                          className="h-4 w-4"
                          fill="#B9B8B7"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                        </svg>
                      </span>
                      <span>{s.area}</span>
                    </li>
                    <li className="flex gap-2">
                      <span>
                        <svg
                          className="h-4 w-4"
                          viewBox="0 0 23 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.425 13.7143H21.85C20.9142 13.7143 20.2041 13.4136 19.8343 13.075C19.5144 12.7818 19.1277 12.5714 18.6925 12.5714H18.1067C17.6715 12.5714 17.2849 12.7818 16.965 13.075C16.5959 13.4136 15.8858 13.7143 14.95 13.7143C14.0142 13.7143 13.3041 13.4136 12.9343 13.075C12.6144 12.7818 12.2277 12.5714 11.7925 12.5714H11.2067C10.7715 12.5714 10.3849 12.7818 10.065 13.075C9.69594 13.4136 8.98581 13.7143 8.05 13.7143C7.11419 13.7143 6.40406 13.4136 6.03427 13.075C5.71442 12.7818 5.32773 12.5714 4.89253 12.5714H4.30675C3.87155 12.5714 3.48486 12.7818 3.16502 13.075C2.79594 13.4136 2.08581 13.7143 1.15 13.7143H0.575C0.257312 13.7143 0 13.97 0 14.2857V15.4286C0 15.7443 0.257312 16 0.575 16H1.15C2.53791 16 3.76337 15.5646 4.6 14.8629C5.43663 15.565 6.66209 16 8.05 16C9.43791 16 10.6634 15.5646 11.5 14.8629C12.3366 15.565 13.5621 16 14.95 16C16.3379 16 17.5634 15.5646 18.4 14.8629C19.2366 15.565 20.4621 16 21.85 16H22.425C22.7427 16 23 15.7443 23 15.4286V14.2857C23 13.97 22.7427 13.7143 22.425 13.7143ZM8.05 12.5714V9.14286H14.95V12.5714C15.6371 12.5714 16.059 12.3514 16.1859 12.235C16.5154 11.9329 16.8759 11.7229 17.25 11.5896V3.42857C17.25 2.79857 17.7661 2.28571 18.4 2.28571C19.0339 2.28571 19.55 2.79857 19.55 3.42857V4C19.55 4.31571 19.8073 4.57143 20.125 4.57143H21.275C21.5927 4.57143 21.85 4.31571 21.85 4V3.42857C21.85 1.53786 20.3025 0 18.4 0C16.4975 0 14.95 1.53786 14.95 3.42857V6.85714H8.05V3.42857C8.05 2.79857 8.56606 2.28571 9.2 2.28571C9.83394 2.28571 10.35 2.79857 10.35 3.42857V4C10.35 4.31571 10.6073 4.57143 10.925 4.57143H12.075C12.3927 4.57143 12.65 4.31571 12.65 4V3.42857C12.65 1.53786 11.1025 0 9.2 0C7.29747 0 5.75 1.53786 5.75 3.42857V11.5893C6.12411 11.7225 6.48456 11.9329 6.81411 12.2346C6.94097 12.3514 7.36287 12.5714 8.05 12.5714Z"
                            fill="#B9B8B7"
                          />
                        </svg>
                      </span>
                      <span>Pool</span>
                    </li>
                    <li className="flex gap-2">
                      <span>
                        <svg
                          width="18"
                          height="13"
                          viewBox="0 0 18 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.35959 3.63708C4.2717 3.63708 4.20044 3.70835 4.20044 3.79624V4.808H7.52675V3.7962C7.52675 3.70831 7.45553 3.63708 7.36763 3.63708H4.35959Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M10.6324 3.63708C10.5445 3.63708 10.4733 3.70835 10.4733 3.79624V4.808H13.7996V3.79624C13.7996 3.70835 13.7283 3.63712 13.6405 3.63712H10.6324V3.63708Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M3.13525 4.79747V3.79611C3.13525 3.12101 3.68449 2.57176 4.3596 2.57176H7.36764C8.04274 2.57176 8.59199 3.12101 8.59199 3.79611V4.79747H9.408V3.79611C9.408 3.12101 9.95725 2.57176 10.6324 2.57176H13.6404C14.3155 2.57176 14.8647 3.12101 14.8647 3.79611V4.79747H15.7125C15.8779 4.79747 16.0394 4.81424 16.1956 4.84602V1.57873C16.1956 0.875679 15.6256 0.305725 14.9226 0.305725H3.07742C2.37436 0.305725 1.80444 0.875679 1.80444 1.57873V4.84602C1.96057 4.81427 2.12212 4.79747 2.28749 4.79747H3.13525Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M17.34 8.09888H0.659988C0.295488 8.09888 0 8.39437 0 8.75887V13.0847C0 13.4492 0.295488 13.7447 0.659988 13.7447H17.34C17.7045 13.7447 18 13.4492 18 13.0847V8.75887C18 8.39437 17.7045 8.09888 17.34 8.09888Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M15.7125 5.86267H2.28742C1.60173 5.86267 1.03543 6.37244 0.945923 7.03359H17.054C16.9645 6.37244 16.3982 5.86267 15.7125 5.86267Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M0.659668 14.8098V15.1617C0.659668 15.4558 0.898133 15.6943 1.19228 15.6943C1.48644 15.6943 1.7249 15.4558 1.7249 15.1617V14.8098H0.660055C0.659914 14.8098 0.659809 14.8098 0.659668 14.8098Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M16.2751 14.8098V15.1617C16.2751 15.4558 16.5136 15.6943 16.8078 15.6943C17.1019 15.6943 17.3404 15.4558 17.3404 15.1617V14.8098C17.3402 14.8098 17.3401 14.8098 17.34 14.8098H16.2751V14.8098Z"
                            fill="#B9B8B7"
                          />
                        </svg>
                      </span>
                      <span>{s.bathrooms} Bedrooms</span>
                    </li>
                    <li className="flex space-x-2">
                      <span>
                        <svg
                          width="18"
                          height="13"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.40137 4.49823C5.40137 4.33261 5.26709 4.19836 5.1015 4.19836C4.93591 4.19836 4.80164 4.33261 4.80164 4.49823V4.7981C4.80164 4.96373 4.93588 5.09797 5.1015 5.09797C5.26713 5.09797 5.40137 4.96373 5.40137 4.7981V4.49823Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M5.40137 5.99762C5.40137 5.832 5.26709 5.69775 5.1015 5.69775C4.93591 5.69775 4.80164 5.832 4.80164 5.99762V6.29749C4.80164 6.46311 4.93588 6.59736 5.1015 6.59736C5.26713 6.59736 5.40137 6.46311 5.40137 6.29749V5.99762Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M5.1015 7.19714C4.93588 7.19714 4.80164 7.33142 4.80164 7.49701V7.79688C4.80164 7.9625 4.93588 8.09675 5.1015 8.09675C5.26713 8.09675 5.40137 7.9625 5.40137 7.79688V7.49701C5.40137 7.33142 5.26713 7.19714 5.1015 7.19714Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M6.60096 4.49823C6.60096 4.33261 6.46671 4.19836 6.30109 4.19836C6.13546 4.19836 6.00122 4.33261 6.00122 4.49823V4.7981C6.00122 4.96373 6.13546 5.09797 6.30109 5.09797C6.46671 5.09797 6.60096 4.96373 6.60096 4.7981V4.49823Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M6.60096 5.99762C6.60096 5.832 6.46671 5.69775 6.30109 5.69775C6.13546 5.69775 6.00122 5.832 6.00122 5.99762V6.29749C6.00122 6.46311 6.13546 6.59736 6.30109 6.59736C6.46671 6.59736 6.60096 6.46311 6.60096 6.29749V5.99762Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M6.30097 7.19714C6.13534 7.19714 6.0011 7.33142 6.0011 7.49701V7.79688C6.0011 7.9625 6.13534 8.09675 6.30097 8.09675C6.46659 8.09675 6.60084 7.9625 6.60084 7.79688V7.49701C6.60087 7.33142 6.46659 7.19714 6.30097 7.19714Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M4.20191 4.49823C4.20191 4.33261 4.06767 4.19836 3.90204 4.19836C3.73642 4.19836 3.60217 4.33261 3.60217 4.49823V4.7981C3.60217 4.96373 3.73642 5.09797 3.90204 5.09797C4.06767 5.09797 4.20191 4.96373 4.20191 4.7981V4.49823Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M4.20191 5.99762C4.20191 5.832 4.06767 5.69775 3.90204 5.69775C3.73642 5.69775 3.60217 5.832 3.60217 5.99762V6.29749C3.60217 6.46311 3.73642 6.59736 3.90204 6.59736C4.06767 6.59736 4.20191 6.46311 4.20191 6.29749V5.99762Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M3.90204 7.19714C3.73642 7.19714 3.60217 7.33142 3.60217 7.49701V7.79688C3.60217 7.9625 3.73642 8.09675 3.90204 8.09675C4.06767 8.09675 4.20191 7.9625 4.20191 7.79688V7.49701C4.20191 7.33142 4.06763 7.19714 3.90204 7.19714Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M9.30021 10.046V9.14635C9.30021 9.06355 9.23309 8.9964 9.15026 8.9964H2.40296V2.39905C2.40296 1.40534 3.20852 0.599772 4.20224 0.599772H4.80201V1.82929C4.1038 1.97183 3.60236 2.58608 3.6025 3.29869C3.6025 3.46432 3.73674 3.59856 3.90237 3.59856H6.30142C6.46705 3.59856 6.60129 3.46432 6.60129 3.29869C6.60143 2.58608 6.09999 1.97179 5.40178 1.82929V0.299868C5.40178 0.134243 5.2675 0 5.10191 0H4.20227C2.87727 0 1.80319 1.07409 1.80319 2.39905V8.9964H0.408744C0.18517 8.9964 0.00390625 9.17766 0.00390625 9.40124V9.79107C0.00390625 10.0146 0.18517 10.1959 0.408744 10.1959H9.15026C9.23305 10.1959 9.30021 10.1288 9.30021 10.046Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M17.5914 8.99637H14.8504C14.7677 8.99472 14.6992 9.0605 14.6975 9.1433C14.6975 9.14432 14.6975 9.1453 14.6975 9.14632V10.046C14.6975 10.1288 14.7646 10.1959 14.8475 10.1959H17.5914C17.8149 10.1959 17.9962 10.0146 17.9962 9.79107V9.4012C17.9962 9.17763 17.815 8.99637 17.5914 8.99637Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M16.9318 10.7957H14.8477C14.7649 10.7957 14.6977 10.8628 14.6977 10.9456V13.7945C14.6977 13.9601 14.5635 14.0944 14.3978 14.0944C14.2322 14.0944 14.098 13.9601 14.098 13.7945V13.3447C14.098 13.2619 14.0309 13.1947 13.948 13.1947H13.6482C13.5654 13.1947 13.4982 13.2618 13.4982 13.3447V13.7945C13.4982 13.9601 13.364 14.0944 13.1983 14.0944C13.0327 14.0944 12.8985 13.9601 12.8985 13.7945V13.3447C12.8985 13.2619 12.8313 13.1947 12.7485 13.1947H12.4486C12.3659 13.1947 12.2987 13.2618 12.2987 13.3447V13.7945C12.2987 13.9601 12.1645 14.0944 11.9988 14.0944C11.8332 14.0944 11.699 13.9601 11.699 13.7945V13.3447C11.699 13.2619 11.6318 13.1947 11.549 13.1947H11.2491C11.1663 13.1947 11.0992 13.2618 11.0992 13.3447V13.7945C11.0992 13.9601 10.9649 14.0944 10.7993 14.0944C10.6337 14.0944 10.4995 13.9601 10.4995 13.7945V13.3447C10.4995 13.2619 10.4323 13.1947 10.3495 13.1947H10.0496C9.96684 13.1947 9.89968 13.2618 9.89968 13.3447V13.7945C9.89968 13.9601 9.7654 14.0944 9.59981 14.0944C9.43419 14.0944 9.29994 13.9601 9.29994 13.7945V10.9456C9.29994 10.8628 9.23282 10.7957 9.14999 10.7957H1.06821C0.983724 10.7956 0.915196 10.864 0.915161 10.9485C0.915161 10.9505 0.915196 10.9526 0.915267 10.9546C1.03222 13.0897 1.95885 15.2999 3.31428 15.9806C2.86148 16.4642 2.88646 17.2233 3.37002 17.6761C3.85361 18.1289 4.61268 18.1039 5.06549 17.6203C5.42868 17.2325 5.49338 16.6518 5.22451 16.1935H12.7665C12.591 16.4864 12.5526 16.8415 12.6615 17.1651C12.8728 17.793 13.5531 18.1307 14.1809 17.9194C14.8088 17.7081 15.1465 17.0279 14.9352 16.4C14.8809 16.2385 14.7927 16.0904 14.6767 15.9656C16.0412 15.2848 16.9678 13.0747 17.0757 10.9396C17.0714 10.862 17.0094 10.8001 16.9318 10.7957Z"
                            fill="#B9B8B7"
                          />
                          <path
                            d="M10.0495 12.595H13.9479C14.0307 12.595 14.0978 12.5278 14.0978 12.445V9.14632C14.0995 9.06683 14.0364 9.00108 13.9569 8.99943C13.9559 8.99939 13.9549 8.99939 13.9539 8.99939H10.0555C9.97334 9.00097 9.90713 9.06718 9.90552 9.14934V12.448C9.90703 12.5274 9.97014 12.5918 10.0495 12.595Z"
                            fill="#B9B8B7"
                          />
                        </svg>
                      </span>
                      <span>{s.bathrooms} Bathrooms</span>
                    </li>
                    {/* )
                    })} */}
                  </ul>
                </div>
              </div>
            )
          })}
        </div>
      </section>
    </>
  )
}
