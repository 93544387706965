import React from "react"
import Divide from "./Section/Divide"
import { StaticImage } from "gatsby-plugin-image"
import { FaInstagram } from "react-icons/fa"
export default function FooterArea() {
  return (
    <>
      <Divide
        image={
          <StaticImage
            src="../../images/index3/footer.jpeg"
            alt="second"
            className="h-[28rem] lg:h-[80vh] w-full object-cover"
          />
        }
      >
        <div className="pt-24 md:pt-44 h-full lg:h-[80vh]">
          <h5 className="font-serif3 text-center md:text-left text-3xl sm:text-4xl lg:text-5xl lg:leading-[56px] font-bold text-[#7E7E66]">
            Ready to Plan Your Trip to Ibiza?
          </h5>
          <p className="text-[16px] text-center md:text-left lg:max-w-[28rem] py-8">
            Use our Vacation Planner and design your own Ibiza stay before you
            arrive, to create your ultimate Ibiza experience.
          </p>
          <div className="pb-20 text-center md:text-left">
            <button className="bg-[#EC4B28] font-bold text-white uppercase text-xs px-10 py-4 rounded-full">
              Start Planning →
            </button>
          </div>
        </div>
      </Divide>
      <footer className="max-w-screen-lg w-11/12 xl:w-full mx-auto relative py-6 md:py-0 lg:-mt-12">
        <div className="grid justify-items-center gap-6 sm:gap-0 sm:flex sm:justify-between lg:w-1/2 items-center pr-4">
          <div className="text-xs space-x-2">
            <span>
              © <a href="/">Book Ibiza</a>, {new Date().getFullYear()}
            </span>

            <span>All Right Reserved.</span>
          </div>
          <div className="flex text-xs space-x-3 items-center justify-center">
            <span className="font-bold drop-shadow after:pl-3 after:content-['–']">
              Follow Us
            </span>
            <ul className="flex space-x-3 items-center">
              {[
                {
                  icon: <FaInstagram />,
                  to: "https://instagram.com/bookibiza",
                },
              ].map((s, key) => {
                return (
                  <li
                    key={key}
                    className="h-8 w-8 flex items-center justify-center bg-[#A5A58F] text-white rounded-full"
                  >
                    <a href={s.to} rel="noreferrer" target="_blank">
                      {s.icon}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </footer>
    </>
  )
}
