import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { FaInstagram, FaPlay } from "react-icons/fa"
export default function Hero() {
  return (
    <>
      <section className="grid min-h-screen h-auto lg:h-screen overflow-hidden">
        <div className="col-start-1 row-start-1 py-12 relative z-10 bg-gradient-to-t to-black/50 from-black/0">
          <div className=" w-11/12 xl:w-full max-w-screen-lg mx-auto">
            <header className="grid  gap-16 md:flex md:justify-between items-center">
              <div className="w-44 mx-auto md:mx-0 row-start-2 col-start-1">
                <StaticImage
                  src="../../images/logo-black.png"
                  alt="Stay-tulum"
                  className=" filter invert"
                />
              </div>
              <div className="flex text-sm justify-center space-x-3 items-center z-50 relative row-start-1 col-start-1">
                <div className="flex space-x-3 items-center">
                  <span className="drop-shadow after:pl-3 text-white after:content-['–']">
                    Follow Us
                  </span>
                  <ul className="flex space-x-3 items-center">
                    {[
                      {
                        icon: <FaInstagram />,
                        to: "https://www.instagram.com/bookibiza",
                      },
                    ].map((s, key) => {
                      return (
                        <li
                          key={key}
                          className="h-8 w-8 flex items-center rotate-0 justify-center bg-[#A5A58F] text-white rounded-full"
                        >
                          <a href={s.to} target="_blank">
                            {s.icon}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </header>
            <div className="py-6 md:py-20 w-full md:w-4/6">
              <h1 className="font-serif3 text-2xl leading-[32px] text-center md:text-left sm:text-4xl lg:text-5xl lg:leading-[56px] md:font-bold text-white w-11/12 md:w-full mx-auto md:mx-0">
                Experience Ibiza. <br />
                The best places to stay. <br />
                The most unforgettable experiences.
              </h1>
              <p className="text-[16px] text-white text-center md:text-left md:max-w-[28rem] py-8">
                Book premium vacation rentals, create your custom itinerary and
                get access to exclusive venues and experiences, we’ve done the
                hard work.
              </p>
            </div>
          </div>
        </div>
        <div className="col-start-1 row-start-1 relative z-0 ">
          <StaticImage
            src="../../images/index3/hero-1.png"
            alt="stay tulum"
            className="h-full w-full object-cover"
          />
        </div>
      </section>
      <div className="w-11/12 xl:w-full max-w-screen-lg mx-auto h-64 ">
        {/* <div className="grid items-center max-w-[26rem] w-full z-50 h-64 bg-stone-900 relative -top-32 md:-top-48 shadow-xl shadow-stone-900/20">
          <div className="col-start-1 row-start-1 relative z-0">
            <StaticImage
              src="../../images/index3/play.jpeg"
              className="w-full h-64 object-cover opacity-80"
              alt="Video"
            />
            
          </div>
          <div className="col-start-1 row-start-1 relative z-10 flex justify-center space-x-2">
            <button className="h-16 w-16 rounded-full bg-white flex items-center justify-center">
              <FaPlay />
            </button>
            <div className="grid text-white">
              <span className="font-bold uppercase text-lg">Watch Video</span>
              <span className="font-light text-xs uppercase">
                and Learn more
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}
